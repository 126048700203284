import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import axios from "axios"

export const getStationsByRoute = createAsyncThunk(
  "stations/getStationsByRoute",
  async (params, { rejectWithValue }) => {
    try {
      params.workHours = process.env.REACT_APP_WORK_HOURS
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_KEY}/Stations/getStationsByRoute/${params.routeId}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      )
      return data
    } catch (error) {
      console.error("Error fetching stations:", error)

      let errorPayload = {
        status: null,
        message: "Something went wrong.",
        type: null,
      }

      if (error.response) {
        errorPayload.status = error.response.status
        errorPayload.message =
          error.response.data?.message || "Unauthorized access."
        if (error.response.status === 401) {
          errorPayload.type = "SESSION_EXPIRED"
        }
      } else if (error.request) {
        errorPayload.status = 401
        errorPayload.type = "TOKEN_EXPIRED"
        errorPayload.message = "Your session has expired. Please log in again."
      } else {
        errorPayload.message = error.message
      }

      return rejectWithValue(errorPayload)
    }
  }
)

export const getNearbyStation = createAsyncThunk(
  "stations/getNearbyStation",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/Stations/getStationById/${params.stationId}`,
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const postponeStation = createAsyncThunk(
  "stations/postponeStation",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_KEY}/DriverRouteSession/postponeStation/${params.stationId}/${params.routeId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const stationsSlice = createSlice({
  name: "stations",
  initialState: {
    showStations: false,
    loading: false,
    stationsList: [],
    stationsWithOrdersCount: null,
    stationsWithoutOrdersCount: null,
    selectedStation: null,
    isFromCache: false,
    currentRouteIdFromCache: false,
    lastStationsRequestParams: null,
    recentlyAddedStations: null,
    prevStationList: null,
    orderDetailsClicked: false,
    isSessionExpired: false,
    isTokenExpired: false,
    nearbyStation: null,
    postPoneStationSuccess: false,
    postPoneStationFailed: false,
  },
  reducers: {
    clearNearbyStation: (state) => {
      state.nearbyStation = null
    },
    setSelectedStation: (state, { payload }) => {
      state.selectedStation = payload
    },
    clearStationData: (state) => {
      state.isSessionExpired = false
      state.isTokenExpired = false
      state.showStations = false
      state.loading = false
      state.stationsList = []
      state.stationsWithOrdersCount = null
      state.stationsWithoutOrdersCount = null
      state.selectedStation = null
    },
    clearSessionExpired: (state) => {
      state.isSessionExpired = false
      state.isTokenExpired = false
    },
    setOrderDetailsClicked: (state, { payload }) => {
      state.orderDetailsClicked = payload
    },
    setShowStations: (state, { payload }) => {
      state.showStations = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStationsByRoute.pending, (state, { meta }) => {
        state.loading = true
        state.isSessionExpired = false
        state.isTokenExpired = false
        state.lastStationsRequestParams = Object.assign({}, meta.arg)
        state.lastStationsRequestParams.timeOfRequest = new Date().toString()
      })
      .addCase(getStationsByRoute.fulfilled, (state, { payload }) => {
        state.prevStationList = state.stationsList
        state.loading = false
        state.isSessionExpired = false
        state.isTokenExpired = false
        state.isFromCache = payload.isFromCache
        state.stationsList = payload.stations
        state.stationsWithOrdersCount = payload.stationsWithOrdersCount
        state.stationsWithoutOrdersCount = payload.stationsWithoutOrdersCount
        if (payload.isFromCache == true) {
          state.currentRouteIdFromCache = payload.stations[0].routeId
        }
      })
      .addCase(getStationsByRoute.rejected, (state, { payload }) => {
        state.loading = false
        if (payload?.type === "TOKEN_EXPIRED") {
          state.isTokenExpired = true
          state.errorMessage = payload.message
        } else if (payload?.type === "SESSION_EXPIRED") {
          state.isSessionExpired = true
          state.errorMessage = payload.message
        }
      })

      .addCase(getNearbyStation.fulfilled, (state, { payload }) => {
        state.loading = false
        state.nearbyStation = payload
      })
      .addCase(getNearbyStation.rejected, (state, { payload }) => {
        state.loading = false
      })

      .addCase(postponeStation.fulfilled, (state, { payload }) => {
        state.loading = false
        state.postPoneStationSuccess = payload
      })

      .addCase(postponeStation.rejected, (state, { payload }) => {
        state.loading = false
        state.postPoneStationFailed = payload
      })
  },
})

export const {
  setSelectedStation,
  clearStationData,
  setOrderDetailsClicked,
  clearNearbyStation,
  setShowStations,
  clearSessionExpired,
} = stationsSlice.actions

export default stationsSlice.reducer
