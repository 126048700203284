import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import logo from "../../assets/images/done_logo.svg"
import { Spinner } from "../../features/common/spinner/Spinner.js"
import { sendOTP, verifyOTP, getUserData } from "./loginOTPSlice.js"
import "./loginOTP.scss"

export function LoginOTP() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [errorMsg, setErrorMsg] = useState("")
  const {
    loading,
    loginSuccess,
    loginFail,
    token,
    getUSerSuccess,
    getUserFail,
    userData,
  } = useSelector((state) => state.loginOTP)
  const [step, setStep] = useState(1)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [otp, setOtp] = useState("")
  const phoneInputRef = useRef(null)

  useEffect(() => {
    if (loginSuccess || token) {
      dispatch(getUserData(token))
    }
  }, [loginSuccess])

  useEffect(() => {
    if (loginFail) {
      setErrorMsg("תקלה בשרת. אנא נסה שוב.")
    }
  }, [loginFail])

  useEffect(() => {
    if (getUSerSuccess) {
      if (
        userData.role == "Manager" ||
        userData.role == "DoneDriver" ||
        userData.role == "Driver"
      ) {
        navigate("/MainPage")
      } else {
        setErrorMsg("אינך מורשה להיכנס למערכת.")
      }
    }
  }, [getUSerSuccess])

  const handlePhoneSubmit = (e) => {
    e.preventDefault()
    if (phoneNumber) {
      dispatch(sendOTP(phoneNumber))
      setStep(2)
      if (phoneInputRef.current) {
        phoneInputRef.current.focus()
      }
    }
  }

  const handleOTPSubmit = (e) => {
    e.preventDefault()
    if (otp) {
      dispatch(verifyOTP({ phoneNumber, otp }))
    }
  }

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value
    const sanitizedInput = input.replace(/\D/g, "")
    setPhoneNumber(sanitizedInput)
  }

  return (
    <div className="login-otp-wrapper">
      <div className="enter-text">
        <div>כניסה</div>
        <div className="underline"></div>
      </div>

      {step === 1 ? (
        // Step 1: Enter Phone Number
        <form noValidate onSubmit={handlePhoneSubmit}>
          {errorMsg && <div className="login-fail">{errorMsg}</div>}
          <div className="input-wrapper">
            <label htmlFor="phone">מספר טלפון:</label>
            <input
              type="tel"
              id="phone"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="הזן מספר טלפון"
              ref={phoneInputRef}
              maxLength={10}
              autoFocus
              required
            />
          </div>
          <div className="btn-login-wrapper">
            <button className="btn-login" type="submit" disabled={loading}>
              שלח קוד
            </button>
          </div>
        </form>
      ) : (
        // Step 2: Enter OTP
        <form noValidate onSubmit={handleOTPSubmit}>
          {errorMsg && <div className="login-fail">{errorMsg}</div>}
          <div className="input-wrapper">
            <label htmlFor="otp">קוד אימות:</label>
            <input
              type="text"
              id="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="הזן קוד אימות"
              required
            />
          </div>
          <div className="btn-login-wrapper">
            <button className="btn-login" type="submit" disabled={loading}>
              כניסה
            </button>
            <button
              className="btn-back"
              disabled={loading}
              onClick={() => setStep(1)}
            >
              חזרה
            </button>
          </div>
        </form>
      )}

      {/* {loading && <Spinner />} */}
      <div>
        <img className="logo" src={logo} alt="Logo" />
      </div>
    </div>
  )
}
