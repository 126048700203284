import { configureStore } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore } from "redux-persist"
import { thunk } from "redux-thunk"
import loginReducer from "../pages/loginPage/loginSlice"
import routesReducer from "../features/routes/routesSlice"
import stationsReducer from "../features/stations/stationsSlice"
import driverMapReducer from "../features/driverMap/driverMapSlice"
import mainPageReducer from "../pages/mainPage/mainPageSlice"
import ordersReducer from "../features//orders/ordersSlice"
import barcodeScannerReducer from "../features/barcodeScanner/barcodeScannerSlice"
import stationTaskReducer from "../features/orders/stationTask/stationTaskSlice"
import storageSacksReducer from "../features/orders/storageSacks/storageSacksSlice"
import loginOTPReducer from "../pages/loginOtpPage/loginOTPSlice"
import { getPersistConfig } from "redux-deep-persist"
import { combineReducers } from "redux"

const combinedReducer = combineReducers({
  login: loginReducer,
  loginOTP: loginOTPReducer,
  routes: routesReducer,
  stations: stationsReducer,
  driverMap: driverMapReducer,
  main: mainPageReducer,
  orders: ordersReducer,
  barcodeScanner: barcodeScannerReducer,
  stationTask: stationTaskReducer,
  storageSacks: storageSacksReducer,
})

const rootReducer = (state, action) => {
  if (action.type === "loginOTP/clearUserData") {
    state = undefined
  }
  return combinedReducer(state, action)
}

const config = getPersistConfig({
  key: "root",
  storage,
  blacklist: [
    "stations.loading",
    "stations.isSessionExpired",
    "stations.isTokenExpired",
    "orders.loading",
    "routes.loading",
    "login.loading",
    "login.loginFail",
    "login.getUSerSuccess",
    "login.loginSuccess",
    "loginOTP.loading",
    "loginOTP.getUSerSuccess",
    "loginOTP.getUserFail",
    "loginOTP.loginSuccess",
    "loginOTP.loginFail",
    "barcodeScanner",
  ],
  rootReducer,
})

const persistedReducer = persistReducer(config, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})

export const persistor = persistStore(store)
